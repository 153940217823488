.custom-date-input {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fff;
    cursor: pointer;
    text-align: left; /* Align text to the left */
    box-sizing: border-box;
  }
  
  .custom-date-input:hover {
    background-color: #f1f1f1; /* Slightly darker background on hover */
  }
  
  .custom-date-input:focus {
    outline: none;
    border-color: #007bff; /* Blue border on focus */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Subtle shadow on focus */
  }