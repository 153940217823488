  
  .tour-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 0px 10px;
    max-width: 1200px;
  }
  
  .tour-list-title{
    text-align: center;
    color: #ffffff;
    padding: 10px;
  }

  @media (min-width: 1242px) {
    .tour-list-container {
      display: flex;
      justify-content: center;
    }
  }

  .tour-card {  
    background: linear-gradient(135deg, #ffffff, #f0f0f0);
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .tour-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  .tour-image-container {
    position: relative;
  }
  
  .tour-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-bottom: 4px solid #61dafb;
  }
  
  .tour-price {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 10px 15px;
    border-radius: 4px;
    font-size: 20px;
    font-weight: bold;
  }
  
  .tour-details {
    padding: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }
  
  .tour-details h2 {
    margin: 0 0 15px;
    color: #333;
  }
  
  .tour-details p {
    margin: 8px 0;
    color: #555;
  }
  
  .tour-link {
    display: inline-block;
    margin-top: 15px;
    color: #61dafb;
    font-weight: bold;
    text-decoration: none;
    border: 2px solid #61dafb;
    padding: 10px 15px;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
    align-self: flex-start;
  }
  
  .tour-link:hover {
    background-color: #61dafb;
    color: #fff;
  }
  