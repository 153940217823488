/* src/App.css */

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: url('assets/background.jpg');
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
}

.content {
  padding-top: 50px;
  flex: 1;
  padding: 60px 0 0;
  background-color: rgba(0, 0, 0, 0.820);
}

/* Other styles */
footer {
  background-color: #282c34;
  color: white;
  text-align: center;
  padding: 10px;
}

nav {
  background-color: #282c34;
  color: white;
  padding: 10px;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
}

nav a {
  color: white;
  text-decoration: none;
}

nav a:hover {
  text-decoration: underline;
}
