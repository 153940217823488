/* src/About.css */

.about-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    color: white; /* Ensure text color is white */
  }
  
  .h1-white {
    color: white; /* Set text color for h1 */
  }
  
  .h2-white {
    color: white; /* Set text color for h2 */
  }
  
  .strong-white {
    color: white; /* Set text color for strong */
  }
  
  .about-container a {
    color: white; /* Ensure all links are white */
  }
  
  .about-container a:hover {
    text-decoration: underline; /* Optional: Add underline on hover */
  }
  