.alert-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;
}

.alert {
  color: white;
  padding: 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.alert.error {
  background-color: #f44336; /* Red for errors */
}

.alert.success {
  background-color: #4caf50; /* Green for success */
}

.alert.info {
  background-color: #2196f3; /* Blue for info */
}

.alert i {
  margin-right: 10px;
}

.alert .fas {
  font-size: 20px;
}
