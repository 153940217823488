/* General styling for the book component */
.book-container {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .contact-info-container, .date-selection-container, .persons-container {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-control {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .next-button, .prev-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .next-button {
    background-color: #4caf50;
    color: #fff;
  }
  
  .prev-button {
    margin-right: 2%;
    background-color: #f44336;
    color: #fff;
  }
  
  .next-button:hover, .prev-button:hover{
    transform: scale(1.05);
  }
  
  .total-price {
    font-size: 18px;
    font-weight: bold;
    margin: 15px 0;
  }
  
  .progress-bar {
    height: 10px;
    background-color: #ddd;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .progress-bar-fill {
    height: 100%;
    background-color: #4caf50;
    border-radius: 5px;
    transition: width 0.3s ease;
  }
  