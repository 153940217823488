.details-tab-container {
    background-color: #f9f9f9;
  }
  
  .details-tab-container h2 {
    margin-bottom: 15px;
  }
  
  .price-info {
    margin: 15px 0;
    font-size: 18px;
    font-weight: bold;
  }
  
  .additional-info-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .additional-info-table th, .additional-info-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .additional-info-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .additional-info-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  