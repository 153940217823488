.tour-detail-container {
  background-color: #f9f9f9;
}

.tab-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tabs {
  display: flex;
  gap: 10px;
  border-bottom: 2px solid #61dafb;
  width: 100%;
  justify-content: center;
}

.tab {
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  transition: background-color 0.3s, color 0.3s;
  border-bottom: 2px solid transparent;
}

.tab.active {
  color: #61dafb;
  border-bottom: 2px solid #61dafb;
  background-color: #f9f9f9;
}

.tab-content {
  padding: 5px;
  border-radius: 12px;
  width: 100%;
  margin-top: 20px;
}

.book-now-button {
  background-color: #4caf50;
  color: #fff;
  border: none;
  width: 50%;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s, transform 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 900px) {
  .book-now-button {
    width: 20%;
  }
}

.book-now-button i {
  margin-right: 8px; /* Space between icon and text */
}

.book-now-button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}