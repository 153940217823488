.receipt-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    margin-top: 1%;
    font-family: Arial, sans-serif;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }

  .bookin-tile-text {
    text-align: center; 
    font-weight: bold; 
  }
  
  .receipt-summary, .receipt-contact-info {
    margin-bottom: 20px;
  }
  
  .print-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .print-button:hover {
    background-color: #0056b3;
  }
  
  .error-text{
    color: #fff;
  }