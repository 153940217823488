/* src/components/LocationTab/LocationTab.css */

.tab-section {
    margin-bottom: 20px;
  }
  
  .map-iframe {
    width: 100%;
    height: 55vh;
    border: 0;
    border-radius: 8px;
  }