  
  .contact-info-container label {
    display: block;
    margin-bottom: 15px;
  }
  
  .phone-input {
    display: flex;
    align-items: center;
  }
  
  .country-code {
    padding: 0 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-right: none;
    border-radius: 4px 0 0 4px;
  }
  
  input[type="text"],
  input[type="email"],
  select {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  input[type="text"] {
    flex: 1;
    border-radius: 0 4px 4px 0;
  }
  
  select {
    margin-top: 5px;
  }
  
  .next-button {
    background-color: #4caf50; /* Default color */
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .next-button:hover {
    background-color: #45a049;
    transform: scale(1.05);
  }
  
  .next-button:disabled {
    background-color: #ccc; /* Gray color when disabled */
    cursor: not-allowed;
  }
  
  .form-label{
    margin-bottom: 0;
  }