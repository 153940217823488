.navbar {
  background-color: #61dafb; /* Dark background color */
  color: #fff; /* White text color */
  padding: 10px 0;
  position: fixed; /* Fixed position for the navbar at the top */
  width: 100%; /* Full width */
  top: 0;
  left: 0;
  z-index: 1000; /* Ensure navbar is above other content */
}

.navbar-container {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%; /* Full width */
  position: relative; /* Allows positioning of inner elements */
}

.navbar-logo {
  margin-right: auto; /* Pushes the logo to the left */
}

.navbar-logo img {
  height: 40px; /* Adjust the height as needed */
  width: auto; /* Maintain aspect ratio */
}

.navbar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
  position: absolute; /* Position menu in the center */
  left: 50%; /* Move center point to the left 50% */
  transform: translateX(-50%); /* Adjust by half the width of the menu */
}

.navbar-menu li {
  display: flex;
  align-items: center;
}

.navbar-menu a {
  color: #fff; /* White text color for links */
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
}

.navbar-menu a:hover {
  color: #282c34; /* Accent color for hover effect */
}
