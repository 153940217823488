.date-selection-container {
  background-color: #f9f9f9;
  border-radius: 8px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box; /* Ensure padding does not affect width */
  position: relative; /* Avoid overlap issues */
  z-index: 1; /* Ensure input is above other elements */
}
.form-group{
  width: 100%;
}
.next-button:disabled {
  background-color: #ccc; /* Gray color when disabled */
  cursor: not-allowed;
}


.react-datepicker-wrapper{
  width: 100%;
}
.react-datepicker__input {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}
