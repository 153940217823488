.payment-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.summary-container {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
}

.summary-item {
    margin-bottom: 10px;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 10px;
}

input[type="radio"] {
    margin-right: 8px;
}

.prev-button, .confirm-button {
    margin-top: 10px;
}
