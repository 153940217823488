.footer-container {
    background-color: #2c3e50;
    color: white;
    text-align: center;
    padding: 20px 0;
    font-family: Arial, sans-serif;
  }
  
  .footer-container p {
    margin: 5px 0;
    font-size: 14px;
  }
  
  .footer-container a {
    color: #ecf0f1;
    text-decoration: none;
  }
  
  .footer-container a:hover {
    text-decoration: underline;
  }
  
  .footer-social {
    margin-top: 10px;
  }
  
  .footer-social a {
    margin: 0 10px;
    font-size: 18px;
    color: #ecf0f1;
  }
  
  .footer-social a:hover {
    color: #3498db;
  }
  