.media-tab-container {
    padding: 20px;
  }
  
  .media-tab-container h2 {
    margin-bottom: 15px;
    text-align: center;
  }
  
  .media-grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Default to 3 items per row */
    gap: 10px;
    padding-right: 10px; /* Padding for layout adjustment */
    overflow: hidden; /* Hide overflow to remove scrollbar */
  }
  
  /* Responsive styles */
  @media (max-width: 1200px) {
    .media-grid-container {
      grid-template-columns: repeat(2, 1fr); /* Two items per row for medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .media-grid-container {
      grid-template-columns: repeat(2, 1fr); /* Two items per row for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .media-grid-container {
      grid-template-columns: 1fr; /* One item per row for very small screens */
    }
  }
  
  /* Container for media items to maintain aspect ratio */
  .media-item-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 aspect ratio */
    background: #ddd; /* Light background to make empty space visible */
    border-radius: 8px;
    overflow: hidden;
  }
  
  .media-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure media covers the container */
    border-radius: 8px;
  }
  
  iframe {
    border: 0;
  }
  