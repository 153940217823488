.person-selection-container {
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .total-price {
    margin-top: 15px;
    font-weight: bold;
    color: #4caf50;
  }
  
  .next-button:disabled {
    background-color: #ccc; /* Gray color when disabled */
    cursor: not-allowed;
  }
  
  